import ITTIModel from '../../ittijs/ITTIModel';

export class DocumentsList extends ITTIModel {
	constructor() {
		super('DocumentsList');
	}
}

class BaseDocumentsModel extends ITTIModel {
	approveDocument(params){
		return this.fetch('approveDocument', params);
	}
	setProcessed(params){
		return this.fetch('setProcessed', params);
	}
	getDocs(params){
		return this.fetch('getDocs', params);
	}
}

export class ImotDocumentsModel extends BaseDocumentsModel {
	constructor() {
		super('DocumentsImot');
	}
	async getDocs(imot){
		return super.getDocs({imot});
	}
}

export class StationDocumentsModel extends BaseDocumentsModel {
	constructor() {
		super('DocumentsStation');
	}
	async getDocs(station){
		return super.getDocs({station});
	}
}
