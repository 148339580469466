<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col>
					<list
						:imodel="imodel"
						hide-edit hide-delete hide-select
						headers="utility_code,station_N,imot_N,address,document_type,from_date,to_date,file,created_by,created_time"
						hidden-cols="station_id,imot_id,session_id"
						:filter="filter"
					>
						<template #right-actions="{item}">
							<imot-details-link
								:utility="item.__raw.utility_code"
								:session="item.__raw.session_id"
								:station="item.__raw.station_id"
								:imot="item.__raw.imot_id"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import List from "@/ittijs/List";
import {DocumentsList} from "@/views/documents/Model";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const model = ITTIModelProvider.getModel(DocumentsList);

export default {
	components: {ImotDetailsLink, List, PleaseSelectUtility},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	data(){
		return {
			imodel: model,
		}
	},
	computed: {
		/**
		 * @return FilterGroup
		 */
		filter(){
			return {
				glue: 'AND',
				rules: [
					{
						field: 'approved_by',
						op: 'eq',
						value: '0',
					},
					{
						field: 'utility_code',
						op: 'eq',
						value: this.selectedUtility,
					},
					{
						field: 'document_type',
						op: 'in',
						value: ['41', '42'],
						not: true,
					},
				],
			}
		},
	},
}
</script>